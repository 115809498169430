
import Hero from "./components/hero";
import NoiseCanvas from "./components/utilis/NoiseCanvas";
import './App.css';

function App() {
  return (
    <div id="App" className="App">
      <NoiseCanvas />
      <Hero/>
    </div>
  );
}

export default App;
