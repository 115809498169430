import "./hero.css";
import logo from './img/logo.svg';
import LinkedLogo from "./img/LinkedIn.svg";
import InstagramLogo from "./img/Instagram.svg";

// import herobutton from './img/herobutton.png'
// import Countdown from './countdown.js';


function Hero() {
  return (
    <main>
      <div className='hero'>
        <div className="hackathon-date">
          <h3>Coming to the University of Houston in Fall 2025</h3>
        </div>

        <img src={logo} className="App-logo" alt="Hackathon Logo" />

          <div className="button">
            <a href="https://forms.gle/T83A1qCkfeULjLnYA" target="_blank" rel="noopener noreferrer">
              <div className="text-box">
                <h4>Interest Form!</h4>
              </div>
            </a>
          </div>

          <div className="socials">
            <h4>Connect with us!</h4>
            <div className="logo-box">
              <a href = "https://www.linkedin.com/company/coderedhouston/" target= "_blank" rel="noopener noreferrer">
                <img src={LinkedLogo} className="SocialLogoIMG" alt="LinkedIn Logo" loading="lazy" />
              </a>
              <a href = "https://www.instagram.com/codered_hou/" target= "_blank" rel="noopener noreferrer">
                <img src={InstagramLogo} className="SocialLogoIMG" alt="Instagram Logo" loading="lazy" />
              </a>
            </div>
          </div>
      </div>
    </main>
  );
}

export default Hero;